.page-next {
  background-color: #8fc796 !important;
  color: #fff !important;
  border-radius: 10px;
}
.page {
  background-color: #f8f8f8;
}

.next-btn {
  background-color: #8fc796;
  border: none;
  color: #f8f8f8;
  border-radius: 8px;
  font-family: "Open Sans";
  height: 3.25rem;
  width: 7.5rem;
  padding-top: 0.8rem;
}
.next-btn:hover {
  font-weight: normal;
  color: #8d8d8d;
  cursor: pointer;
  background-color: #8fc796 !important;
  border-color: none;
  outline: none !important;
}

.pre-btn {
  background-color: #8fc796;
  border: none;
  color: #f8f8f8;
  margin-top: 0.6rem;
  border-radius: 8px;
  font-family: "Open Sans";
  height: 2rem;
  width: 3rem;
  padding-top: 0.2rem;
}
.pre-btn:hover {
  font-weight: normal;
  color: #8d8d8d !important;
  cursor: pointer;
  background-color: #8fc796 !important;
  border-color: none;
  outline: none !important;
}

.btn {
  border: none;
}
.next-btn:focus {
  /* background-color: #fff !important; */
  box-shadow: none;
  outline: none !important;
}

.btn-hover-alt {
  font-weight: normal;
  color: #ffffff;
  cursor: pointer;
  background-color: inherit !important;
  border-color: transparent;
}

.counting {
  color: #959595;
  font-family: "Open Sans";
  font-size: 16px;
}
.margin {
  margin-right: -4rem;
  /* align:right; */
}
