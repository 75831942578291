.bg {
  background-image: url("../../images/student.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 110vh;
  width: 100%;
  /* background-position: left center; */
}

@media screen and (max-width: 768px) {
  .bg {
  background-image: url("../../images/studentMbl.png");
    background-repeat: no-repeat; 
    background-size:contain;
    height:110vh;
     width:100%;
    background-position: left center;
     /* min-height: 679px; */
    /* background-size: cover; */
  }
}

.footer {
  /* position: fixed; */
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  color: #8d8d8d;
  text-align: center;
  font-weight: 16px;
}
@media screen and (max-width: 768px) {
.footer {
   left: 0;
   bottom: 0;
   width: 100%;
   background-color: #fff;
   color: #8d8d8d;
   text-align: center;
   font-weight: 16px;
   font-size: 14px;
 }
}



.margin {
  margin-top: 5rem !important;
}
/* .field-size{
    height: 102px !important;
    /* border-radius: 15px !important; */
/* margin-top: 5 */

/* } */
.btn-style {
  height: 102px;
  border-radius: 0.75rem;
  border: none;
  margin-top: 5px;
}
.forgot {
  text-decoration: none;
  color: #8d8d8d;
  margin-top: 10px;
}
#sticky-footer {
  flex-shrink: none;
}
.logo-align {
  margin-top: 120px;
}

@media screen and (max-width: 768px) {
  .logo-align {
    margin-top: 0px;
  }
}
.welcome-text {
  font-size: 20px;
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .welcome-text {
    font-size: 15px;
  }
}

.input-field-align {
  margin-top: 30px;
}
.password-field-align {
  margin-top: 15px;
}
