.nav-pills .nav-link.active,
.nav-pills .nav-link {
  background-color: #fff;
  color: #7e7e7e;
  font-family: "Open Sans";
  padding: 0.45rem 1.5rem;
  border-radius: 0.5rem !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #8fc796 !important;
  color: #fff;
  font-family: "Open Sans";
  padding: 0.45rem 1.5rem;
  border-radius: 0.5rem;
}
.nav-item {
  padding: 0.5rem;
}
.mouse-on:hover{
  background-color: #7e7e7e;
}
