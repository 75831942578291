nav {
  display: none;
  background-color: #8fc796;
  font-family: "Open Sans";
  /* max-height: 82px; */
}

.navbar-light .navbar-brand {
  color: #fff !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff !important;
}
.mbl-logout-btn:active {
  background-color: #8fc796 !important;
}
@media screen and (min-width: 768px) {
  nav {
    max-height: 82px;
  }
}
.logout-btn {
  color: #8d8d8d !important;
}

@media screen and (max-width: 767px) {
  .align-profile {
    margin-bottom: -10px !important;
  }
}

.logout-btn:hover {
  color: #fff !important;
  background-color: #8fc796 !important;
}
@media screen and (max-width: 767px) {
  .logout-btn {
    height: 1rem !important;
    padding-bottom: 25px !important;
  }
}
.logout-btn:focus {
  background-color: #8fc796 !important;
}
.align-profile {
  margin-bottom: 5px;
  margin-right: -55px !important;
}
nav.navbar .nav-link.active {
  font-weight: bold;
}
/* .move-right {
  left: -50px !important;
} */
.mbl-logout-btn {
  /* display: none; */
  visibility: hidden;
}

@media screen and (max-width: 767px) {
  .mbl-logout-btn {
    visibility: visible;
    margin-left: -20px;
    color: #fff !important;
  }
}

@media screen and (max-width: 767px) {
  .drop-btn {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .image {
    display: none;
  }
}
