.nav-pills .nav-link.active,
.nav-pills .nav-link {
  background-color: #fff;
  color: #7e7e7e;
  font-family: "Open Sans";
  padding: 0.45rem 1.5rem;
  border-radius: 0.5rem !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #8fc796 !important;
  color: #fff;
  padding: 0.45rem 1.5rem;
  font-family: "Open Sans";
  border-radius: 0.5rem;
}
.nav-item {
  padding: 0.5rem;
}
#v-pills-tab {
  width: 100%;
}
.values {
  list-style: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
}
.items {
  width: 30rem;
}
.move-right {
  left: -70px !important;
}
.gray-btn {
  background-color: #959595 !important;
  color: #fff !important;
  min-height: 4.5rem;
  padding: 35px !important;
  font-family: "Open Sans";
  font-weight: bold;
}
.green-btn {
  min-height: 4.5rem;
  padding: 35px !important;
  font-family: "Open Sans";
  font-weight: bold;
}
.white-btn {
  min-height: 4.5rem;
  padding: 35px !important;
  font-family: "Open Sans";
  font-weight: bold;
}
.body {
  background-color: #f8f8f8;
}

.btn-white {
  background-color: #fff !important;
  color: #000 !important;
}

/* Button */
.btn-30 {
  width: 30rem;
  margin: 0.25rem auto;
  padding: 1rem !important;
  border-radius: 0.5rem !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  display: block !important;
}
.lock {
  float: right;
}
.custom-padding {
  padding-left: 100px;
  padding-right: 100px;
}
.lesson-menu {
  margin-top: 84px;
}

@media screen and (max-width: 768px) {
  .lesson-menu {
    margin-top: 0px;
  }
}
.nav-pills .nav-item .nav-link:hover{
  background-color: #8d8d8d;
  color: #fff;
}
