body {
  background-color: #f8f8f8;
}

.btn-white {
  background-color: #fff;
  color: #000 !important;
  font-family: "Open Sans";
}
.btn-green {
  background-color: #8fc796 !important;
  color: #fff !important;
  font-family: "Open Sans";
}

/* Button */
.btn-30 {
  background-color: #fff !important;
  width: 30rem;
  margin: 0.25rem auto;
  padding: 1rem !important;
  border-radius: 0.5rem !important;
  font-family: "Open Sans";
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  display: block !important;
}
.btn.btn-30:hover {
  background-color: #8fc796 !important;
}
.btn-30.active {
  background-color: #8fc796 !important;
}

@media screen and (max-width: 768px) {
  .btn-30 {
    width: 18rem !important;
  }
}
